import { initializeApp } from "firebase/app";
import { doc, getFirestore } from "firebase/firestore";

const collectionName = "dashboards-v0";

// Temporarily hardcoding a dashboard for the site
const victorsDashId = "victors-vegas-dashboard";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "sending-temps.firebaseapp.com",
  projectId: "sending-temps",
  storageBucket: "sending-temps.appspot.com",
  messagingSenderId: "398190562135",
  appId: "1:398190562135:web:13c75792e10d66e4bd8c12",
  measurementId: "G-VCV74RT95R",
};

console.log(process.env.NODE_ENV);
console.log("initializing firestore");
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function dashboardDocRef(dashId) {
  return doc(db, collectionName, dashId);
}

export { db, dashboardDocRef, victorsDashId };
