import { useState, useCallback, memo } from "react";

// TODO: tree shaking https://react-chartjs-2.js.org/docs/migration-to-v4/#tree-shaking
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";

import { format } from "date-fns";

const MemoChart = memo(function memoChart({ temps, externalTooltipHandler }) {
  return (
    <Chart
      type="line"
      datasetIdKey="todo"
      options={{
        scales: {
          x: {
            type: "time",
            time: {
              unit: "hour",
            },
          },
        },
        interaction: {
          mode: "x",
          intersect: false,
        },
        plugins: {
          title: {
            display: true,
            text: "Hourly Forecast",
          },
          tooltip: {
            enabled: false,
            position: "nearest",
            external: externalTooltipHandler,
          },
        },
      }}
      data={{
        datasets: [
          {
            id: 1,
            label: "temp",
            data: temps,
            tension: 0.4,
          },
        ],
      }}
    />
  );
});

export default function HourlyForecast({ shouldDisplay, temps }) {
  const [hourlyHoverData, setHourlyHoverData] = useState({});

  const externalTooltipHandler = useCallback((context) => {
    const tempData = context.tooltip.dataPoints[0];

    // hide external tooltip when user not hovering
    if (context.tooltip.opacity === 0) {
      setHourlyHoverData({});
      return;
    }

    const epochSeconds = tempData.parsed.x;

    if (hourlyHoverData.ts !== epochSeconds) {
      setHourlyHoverData({
        ts: epochSeconds,
        temp: tempData.formattedValue,
      });
    }
  }, []);

  if (!shouldDisplay) {
    return;
  }

  function fmtTs(ts) {
    return format(ts, "iiii h bbb");
  }

  return (
    <>
      <MemoChart
        temps={temps}
        externalTooltipHandler={externalTooltipHandler}
      />
      <div className="row text-center">
        <div className="col-md-4 offset-md-4">
          {hourlyHoverData.hasOwnProperty("ts") ? (
            `${fmtTs(hourlyHoverData.ts)} - ${hourlyHoverData.temp} °F`
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    </>
  );
}
