import "./App.css";

import Dashboard from "./components/dashboard.js";

export default function App() {
  return (
    <div className="App">
      <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
          <div className="navbar-brand ms-3 mt-3">
            Sending Temps
            <span className="badge bg-primary ms-2">ALPHA</span>
          </div>
          <div className=""></div>
        </div>
      </nav>
      <Dashboard />
    </div>
  );
}

// this lived in Dashboard
/* this has been disabled. will reimplement after localstorage is implemented */
// const [locations, setLocations] = useState(parseDashboardFromURL());
// TODO: error handling
// http://localhost:3000/?dashv0=%5B%5B%22Calico%20Basin%22%2C6.15949643971632%2C-115.41904505342247%2C113%2C99%2C%22VEF%22%5D%2C%5B%22Red%20Rock%20Canyon%22%2C36.11366314592959%2C-115.48231802880765%2C110%2C97%2C%22VEF%22%5D%2C%5B%22Lee%20Canyon%22%2C36.304819631907215%2C-115.67891120910644%2C104%2C107%2C%22VEF%22%5D%5D
// function parseDashboardFromURL() {
//   const queryParameters = new URLSearchParams(window.location.search);
//   const dashParam = queryParameters.get("dashv0");

//   const parsedLocations = JSON.parse(dashParam);

//   return parsedLocations.map((loc) => {
//     return {
//       name: loc[0],
//       lat: loc[1],
//       long: loc[2],
//       gridX: loc[3],
//       gridY: loc[4],
//       office: loc[5],
//     };
//   });
// }
