export default function Periods({ mapclickData, gridpointForecastData }) {
  if (
    mapclickData.status === "pending" ||
    gridpointForecastData.status === "pending"
  ) {
    return <p>loading...</p>;
  } else {
    const mapClickDays = parseMapClickDataV2(mapclickData);
    const gridpointDays = parseGridpointForecastDataIntoDays(
      gridpointForecastData
    );
    return (
      <div className="periods row text-center mb-2">
        {/* the periods we get from mapclick and gridpoint forecasts may be offset,
         so one might have 7 days and one might have 8 days :(. For now I solve this by just
         presenting a max. 7 day forecast, which seems like enough anyway */}
        {mapClickDays.slice(0, 7).map((mcDay, i) => (
          <Period
            key={mcDay.date}
            mapClickDay={mcDay}
            gridPointDay={gridpointDays[i]}
          />
        ))}
      </div>
    );
  }
}

// this parses mapclick data into an array of date objects. each date object has
// a date value (its datestring), and a data value. The data value is an object with optional
// day and night values.
function parseMapClickDataV2(mapClickData) {
  const parsed = {};
  // we always have 14 periods
  for (let i = 0; i < 14; i++) {
    const time = new Date(mapClickData.time.startValidTime[i]);

    const timeKey = time.toDateString();

    if (!parsed.hasOwnProperty(timeKey)) {
      parsed[timeKey] = {};
    }

    const halfDayData = {
      name: mapClickData.time.startPeriodName[i],
      temperature: mapClickData.data.temperature[i],
      precipChance: mapClickData.data.pop[i],
      shortForecast: mapClickData.data.weather[i],
      icon: mapClickData.data.iconLink[i],
      time: time,
      isDaytime: time.getHours() < 12, // mapclick data doesn't include the isDaytime field
    };

    if (halfDayData.isDaytime) {
      parsed[timeKey]["daytime"] = halfDayData;
    } else {
      parsed[timeKey]["nighttime"] = halfDayData;
    }
  }

  const sortedDataArr = Object.entries(parsed)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]))
    .map((d) => {
      return { date: d[0], data: d[1] };
    });

  return sortedDataArr;
}

function parseGridpointForecastDataIntoDays(gridpointData) {
  // console.log(gridpointData);
  const parsed = {};

  const periods = gridpointData.properties.periods;
  for (const period of periods) {
    const time = new Date(period.startTime);
    const timeKey = time.toDateString();

    if (!parsed.hasOwnProperty(timeKey)) {
      parsed[timeKey] = {};
    }

    const halfDayData = {
      isDaytime: period.isDaytime,
      windSpeed: period.windSpeed,
      windDirection: period.windDirection,
    };

    if (period.isDaytime) {
      parsed[timeKey]["daytime"] = halfDayData;
    } else {
      parsed[timeKey]["nighttime"] = halfDayData;
    }
  }

  const sortedDataArr = Object.entries(parsed)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]))
    .map((d) => {
      return { date: d[0], data: d[1] };
    });

  return sortedDataArr;
}

// TODO: Giving up on this because of the stupid PT1H interval component of gridpoint forecast data
// i need to parse the interval data and pad it out.
// instead for now i'm going to read the wind for the day straight out of the regular forecast
//
// function dailyMaxWindData(gridpointData) {
//   const hourlyWindSpeed = gridpointData.properties.windSpeed.values;
//   const hourlyWindDir = gridpointData.properties.windDirection.values;

//   const windGroupedByDate = hourlyWindSpeed.reduce(
//     (acc, { validTime, value }) => {
//       const [ts, interval] = validTime.split("/");

//       const time = new Date(ts);
//       const date = time.toDateString();

//       if (!acc.hasOwnProperty(date)) {
//         acc[date] = [];
//       }
//       acc[date].push(value);
//       return acc;
//     },
//     {}
//   );

//   const windDirectionGroupedByDate =

//   return windGroupedByDate;
//   // gridpointData.periods.reduce((acc, startTime, windSpeed, windDirection));
// }

function Period({ mapClickDay, gridPointDay }) {
  const mcData = mapClickDay.data;
  const isDaytime = mcData.hasOwnProperty("daytime");
  const selector = isDaytime ? "daytime" : "nighttime";

  const periodName = mcData[selector].name;
  const icon = mcData[selector].icon;
  const shortForecast = mcData[selector].shortForecast;

  // console.log(gridPointDay.data);
  // incorporate data from gridpoint forecast
  const windSpeed = gridPointDay.data[selector].windSpeed;
  const windDirection = gridPointDay.data[selector].windDirection;

  return (
    <div className="periodContainer col">
      <div className="periodHeader mb-3">
        <span className="periodName">{periodName}</span>
      </div>
      <img className="icon mb-1" src={icon} alt="" />
      <div className="temps mb-2">
        <span className="high">{mcData.daytime?.temperature}</span>{" "}
        {mcData.daytime?.temperature !== null &&
          mcData.nighttime?.temperature !== null && (
            <div className="temp-separator"></div>
          )}
        <span className="low">{mcData.nighttime?.temperature}</span>
      </div>
      <div className="precip-label-container">
        <p>{`${windSpeed} ${windDirection}`}</p>
      </div>
      {/* short forecast is a candidate for removal */}
      <div className="short-forecast-label-container">
        <p>{shortForecast}</p>
      </div>
      {/*
      // experimenting with hiding precipchance since noaa icon already includes that info
      // dashboard is currently very dense so i need to remove redundant data. chance precip can live in detail view?
      {day.precipChance !== null && (
        <div className="precip-label-container">
          <p>{`${day.precipChance}% chance of precip`}</p>
        </div>
      )} */}
    </div>
  );
}
