import { useState, useEffect } from "react";

import HourlyForecast from "./hourly-forecast.js";
import Periods from "./periods.js";
import { victorsDashId, dashboardDocRef } from "../services/firestore";
import "./forecast.css";

import { Button, Modal, Collapse } from "react-bootstrap";
import { Trash3 } from "react-bootstrap-icons";

import { updateDoc, arrayRemove } from "firebase/firestore";

import ky from "ky";

import {
  fmtGetMapClickForecastJson,
  fmtGetGridPointsForecastRequest,
  fmtGetGridpointsRequest,
} from "../modules/noaa_api_client.js";

export default function Forecast({
  name,
  lat,
  long,
  office,
  gridX,
  gridY,
  resyncDashboard,
}) {
  const [mapclickData, setMapclickData] = useState({ status: "pending" });
  const [gridpointForecastData, setGridpointForecastData] = useState({
    status: "pending",
  });
  const [gridpointHourlyForecastData, setGridpointHourlyForecastData] =
    useState({
      status: "pending",
    });
  const [error, setError] = useState(null);

  const [displayHourlyData, setDisplayHourlyData] = useState(false);
  const [showDeleteConf, setShowDeleteConf] = useState(false);

  const fmtNoaaBacklinkFromLatLong = (lat, long) => {
    return `https://forecast.weather.gov/MapClick.php?lat=${lat}&lon=${long}`;
  };

  useEffect(() => {
    async function queryAPI() {
      try {
        const responses = await Promise.all([
          ky.get(fmtGetMapClickForecastJson(lat, long)).json(),
          ky.get(fmtGetGridPointsForecastRequest(office, gridX, gridY)).json(),
          ky.get(fmtGetGridpointsRequest(office, gridX, gridY)).json(),
        ]);

        setMapclickData(responses[0]);
        setGridpointForecastData(responses[1]);
        setGridpointHourlyForecastData(responses[2]);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    }

    queryAPI();
  }, []);

  function handleTrashClick() {
    setShowDeleteConf(true);
  }

  function handleDeleteConfClose() {
    setShowDeleteConf(false);
  }

  async function handleDeleteLocation(
    name,
    latitude,
    longitude,
    office,
    gridX,
    gridY
  ) {
    const dashRef = dashboardDocRef(victorsDashId);

    await updateDoc(dashRef, {
      locations: arrayRemove({
        name: name,
        latitude: latitude,
        longitude: longitude,
        office: office,
        gridX: gridX,
        gridY: gridY,
      }),
    });

    console.log(`removed location: ${name}`);

    resyncDashboard();

    setShowDeleteConf(false);
  }

  function celsiusToFahrenheit(c) {
    return c * 1.8 + 32;
  }

  function hourlyTemps() {
    if (gridpointHourlyForecastData.status === "pending") {
      return [];
    }

    const d = gridpointHourlyForecastData.properties.temperature.values;

    return d.map((period) => {
      return {
        x: period.validTime.split("/")[0],
        y: celsiusToFahrenheit(period.value),
      };
    });
  }

  return (
    <div className="forecast">
      <div className="forecast-top-bar row mb-2 pe-2 pe-sm-5">
        <h4 className="forecast-label col-auto me-auto">
          <a href={fmtNoaaBacklinkFromLatLong(lat, long)}>{`${name}`}</a>
        </h4>
        <Button
          variant="outline-danger"
          className="col-auto"
          onClick={() => {
            handleTrashClick();
          }}
        >
          <Trash3 />
        </Button>

        <Modal show={showDeleteConf} onHide={handleDeleteConfClose}>
          <Modal.Body>
            Are you sure you want to remove this location from your dashboard?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDeleteConfClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                handleDeleteLocation(name, lat, long, office, gridX, gridY);
              }}
            >
              Delete {name}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {mapclickData.status !== "pending" && (
        <div className="current-condies row bg-info-subtle">
          <p className="col">
            Current conditions at {mapclickData.currentobservation.name}:{" "}
            <span className="me-3">
              Temp {mapclickData.currentobservation.Temp}°F
            </span>
            <span className="me-3">
              Wind {mapclickData.currentobservation.Winds} MPH
            </span>
            <span>Humidity {mapclickData.currentobservation.Relh}%</span>
          </p>
        </div>
      )}
      {/* TODO: enhancement - push loading state down into periods. will be useful to render partial data instead of waiting */}
      <Periods
        mapclickData={mapclickData}
        gridpointForecastData={gridpointForecastData}
      />
      <Button
        className="mb-4"
        onClick={() => setDisplayHourlyData(!displayHourlyData)}
      >
        Hourly Data
      </Button>
      <Collapse className="mb-3" in={displayHourlyData}>
        <HourlyForecast
          shouldDisplay={displayHourlyData}
          temps={hourlyTemps()}
        />
      </Collapse>
      {error !== null && <p>{error}</p>}
    </div>
  );
}
