import { dashboardDocRef, victorsDashId } from "../services/firestore";
import { getDoc } from "firebase/firestore";

import Forecast from "../components/forecast";
import NewLocationForm from "./new-location-form";

import { useEffect, useState } from "react";

export default function Dashboard() {
  // this is probably an unconventional/hacky way to force the component to resync
  // with firebase. the idea is that once a new location is submitted the add loc logic
  // will increment this counter. This component's dashboard effect uses this dashVersion as
  // a dependency, so it will notice the change and re-query firebase
  // I want a firebase resync so that we get confirmation the save persisted
  const [dashVersion, setDashVersion] = useState(0);

  const [dashState, setDashState] = useState({
    queryStatus: "pending",
    data: {},
  });

  function resyncDashboard() {
    setDashVersion(dashVersion + 1);
  }

  useEffect(() => {
    async function queryFirestore() {
      const docRef = dashboardDocRef(victorsDashId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log(`query succeeded: ${docSnap.data()} `);
        console.log(docSnap.data());
        setDashState({
          queryStatus: "success",
          data: docSnap.data(),
        });
      } else {
        setDashState({
          queryStatus: "error",
          data: {},
        });
        console.log(`query could not find document`);
      }
    }

    queryFirestore();
  }, [dashVersion]);

  return (
    <div className="dashboard container mt-5">
      {dashState.queryStatus === "pending"
        ? "Loading..." // TODO: improve loading state
        : dashState.data.locations.map((loc) => (
            <Forecast
              key={loc.name}
              name={loc.name}
              lat={loc.latitude}
              long={loc.longitude}
              office={loc.office}
              gridX={loc.gridX}
              gridY={loc.gridY}
              resyncDashboard={resyncDashboard}
            />
          ))}
      <NewLocationForm resyncDashboard={resyncDashboard} />
      <footer className="border-top text-center mt-5">
        <p className="text-muted mt-2">
          powered by{" "}
          <a className="link-info" href="https://www.weather.gov/">
            NOAA
          </a>{" "}
          data
        </p>
      </footer>
    </div>
  );
}
