const noaaApiBase = "https://api.weather.gov";
const weatherGovBase = "https://forecast.weather.gov";

export function fmtConvertLatLongToGridPointsRequest(lat, long) {
  return `${noaaApiBase}/points/${lat},${long}`;
}

// Returns data about the grid tile and hourly weather data
// Does not include icons, the text descriptions found in the ui, or current observations
// data is in SI units
export function fmtGetGridpointsRequest(office, gridX, gridY) {
  return `${noaaApiBase}/gridpoints/${office}/${gridX},${gridY}`;
}

// Returns data about the grid tile and hourly weather data
// can toggle SI/US units with query params
export function fmtGetGridpointsHourlyForecastRequest(office, gridX, gridY) {
  return `${noaaApiBase}/gridpoints/${office}/${gridX},${gridY}/forecast/hourly?units=us`;
}

export function fmtGetGridPointsForecastRequest(office, gridX, gridY) {
  return `${noaaApiBase}/gridpoints/${office}/${gridX},${gridY}/forecast`;
}

///MapClick.php?lat=37.27105000000006&lon=-122.01400999999998&unit=0&lg=english&FcstType=json
export function fmtGetMapClickForecastJson(lat, long) {
  return `${weatherGovBase}/MapClick.php?lat=${lat}&lon=${long}&FcstType=json`;
}
