import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { victorsDashId, dashboardDocRef } from "../services/firestore";
import { updateDoc, arrayUnion } from "firebase/firestore";

import { useState } from "react";
import { fmtConvertLatLongToGridPointsRequest } from "../modules/noaa_api_client";

import ky from "ky";

class ValidationError extends Error {
  constructor(message) {
    super(message);
    this.name = "ValidtionError";
  }
}

export default function NewLocationForm({ resyncDashboard }) {
  const [validationState, setValidationState] = useState({});
  const [isLoading, setLoading] = useState(false);

  function parseLatLongFromUrl(url) {
    console.log("parsing lat/long");
    const parsedUrl = new URL(url);

    if (parsedUrl.host !== "forecast.weather.gov") {
      throw new ValidationError("Please provide a forecast.weather.gov url");
    }

    const params = parsedUrl.searchParams;

    const latParam = params.get("lat");
    const lonParam = params.get("lon");

    const textField1Param = params.get("textField1");
    const textField2Param = params.get("textField2");

    let parsedLat, parsedLong;
    if (latParam !== null && lonParam !== null) {
      parsedLat = Number(latParam);
      parsedLong = Number(lonParam);
    }

    if (textField1Param !== null && textField2Param !== null) {
      parsedLat = Number(textField1Param);
      parsedLong = Number(textField2Param);
    }

    if (isNaN(parsedLat) || isNaN(parsedLong)) {
      throw new ValidationError("Url must have numeric lat and lon params");
    }

    return { latitude: parsedLat, longitude: parsedLong };
  }

  async function saveNewLocation(
    name,
    latitude,
    longitude,
    office,
    gridX,
    gridY
  ) {
    const dashRef = dashboardDocRef(victorsDashId);

    await updateDoc(dashRef, {
      locations: arrayUnion({
        name: name,
        latitude: latitude,
        longitude: longitude,
        office: office,
        gridX: gridX,
        gridY: gridY,
      }),
    });

    console.log(`saved new location: ${name}: ${latitude} | ${longitude}`);

    resyncDashboard();
  }

  // theres a lotta lines of duplicated code since we're updating validation state here and in onsubmit...
  async function handleUrlChange(evt) {
    evt.preventDefault();

    // Only revalidate if first form submission had an error. This way we don't make the form ugly by validating
    // with every key stroke when a user first submits. onBlur would be more appropro
    if (validationState.hasOwnProperty("url") !== true) {
      return;
    }

    const url = evt.currentTarget.value;

    try {
      parseLatLongFromUrl(url);
      setValidationState((prevState) => {
        return { ...prevState, ...{ url: false } };
      });
    } catch (err) {
      setValidationState((prevState) => {
        return { ...prevState, ...{ url: err.message } };
      });
    }
  }

  // theres a lotta lines of duplicated code since we're updating validation state here and in onsubmit...
  async function handleNameChange(evt) {
    evt.preventDefault();

    // Only revalidate if first form submission had an error. This way we don't make the form ugly by validating
    // with every key stroke when a user first submits. onBlur would be more appropro
    if (validationState.hasOwnProperty("name") !== true) {
      return;
    }

    const name = evt.currentTarget.value;
    if (!name) {
      setValidationState((prevState) => {
        return {
          ...prevState,
          ...{ name: "Name cannot not be blank" },
        };
      });
    } else {
      setValidationState((prevState) => {
        return {
          ...prevState,
          ...{ name: false },
        };
      });
    }
  }

  async function handleSubmit(evt) {
    evt.preventDefault();

    setLoading(true);

    // I made this handler a button onclick rather than a form onSubmit so that I have fine grained control
    // over button loading state
    const submitButton = evt.currentTarget;
    console.log(submitButton);
    const form = submitButton.parentNode.parentNode.parentNode;
    console.log(form);
    const formData = new FormData(form);

    const name = formData.get("name");
    if (!name) {
      setValidationState((prevState) => {
        setLoading(false);
        return { ...prevState, ...{ name: "Name cannot be blank" } };
      });
    }

    const url = formData.get("url");
    let latitude, longitude;
    try {
      ({ latitude, longitude } = parseLatLongFromUrl(url));
    } catch (err) {
      setValidationState((prevState) => {
        return {
          ...prevState,
          ...{ url: err.message },
        };
      });
      setLoading(false);
    }

    let office, gridX, gridY;
    try {
      const response = await ky
        .get(fmtConvertLatLongToGridPointsRequest(latitude, longitude))
        .json();

      office = response.properties.gridId;
      gridX = response.properties.gridX;
      gridY = response.properties.gridY;
    } catch (err) {
      setLoading(false);
      console.log("error converting lat/long to gridpoints");
      console.log(err);
      // TODO: render error in form
    }

    try {
      saveNewLocation(name, latitude, longitude, office, gridX, gridY);
      form.reset();
    } catch (err) {
      setLoading(false);
      console.log("Error saving location to firebase");
      console.log(err);
      // TODO: render error in form
    }

    setLoading(false);
  }

  // https://react-bootstrap.github.io/forms/validation/
  // ENHANCEMENT: validate onBlur rather than on submit
  return (
    <Form noValidate className="rows-cols-lg-auto align-items-center mt-5 mb-5">
      <h3>Add a location</h3>
      <strong>Instructions:</strong>
      <ol>
        <li>
          Open{" "}
          <a href="https://www.weather.gov/" target="_blank" rel="noreferrer">
            weather.gov
          </a>{" "}
          in another tab
        </li>
        <li>
          Use the search box in that site's upper left hand corner to find your
          location
        </li>
        <li>
          For increased accurracy click on your exact location on the forecast's
          map
        </li>
        <li>Paste the weather.gov forecast url into this form</li>
      </ol>
      <p></p>
      <div className="row mb-3">
        <Form.Label className="col-sm-2 col-form-label">
          Location Name
        </Form.Label>
        <div className="col-sm-3">
          <Form.Control
            name="name"
            onChange={handleNameChange}
            isInvalid={!!validationState.name}
            placeholder="Red Rock Canyon"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {validationState.name}
          </Form.Control.Feedback>
        </div>
      </div>
      <div className="row mb-3">
        <Form.Label className="col-sm-2 col-form-label">URL</Form.Label>
        <div className="col-sm-8">
          <Form.Control
            name="url"
            onChange={handleUrlChange}
            isInvalid={!!validationState.url}
            placeholder="https://forecast.weather.gov/MapClick.php?lat=36.15949643971632&lon=-115.41904505342247"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {validationState.url}
          </Form.Control.Feedback>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <Button variant="info" disabled={isLoading} onClick={handleSubmit}>
            {isLoading ? "Submitting" : "Add Location"}
          </Button>
        </div>
      </div>
    </Form>
  );
}
